// ... other imports
import React, { useEffect, useState } from "react";
import { Slide } from "react-awesome-reveal";

import Dropdown from "./Dropdown";
import NavItem from "./NavItem";
import menuStore from "./store/menuStore";
import { useLocation, useNavigate } from "react-router-dom";
import { FaCaretLeft } from "react-icons/fa";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoClose } from "react-icons/io5";
import axios from "axios";
import { getAxiosBaseUrl } from "../../config";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { menudata as menu } from "../../Components/StructureManagement/menudata";
import UserMenu from "./UserMenu";

const NavBar = ({ isLocalRun, signedin, verifiedemail }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuData = menu.menudata;

  // const { menuData, fetchMenuData } = menuStore((state) => ({
  //   menuData: state.menuData,
  //   fetchMenuData: state.fetchMenuData,
  // }));
  // const {fetchMenuData1} = menuStore((menuState) => ({
  //     fetchMenuData1: menuState.fetchMenuData
  // }));
  // const menuData = [];

  const location = useLocation();
  const pathname = location.pathname;

  // Zustand Get Data from Backend
  // useEffect(() => {
  //   fetchMenuData();
  // }, []); // Empty array ensures it runs only once

  const allItems = Object.entries(menuData).flatMap(([sectionKey, section]) =>
    Object.entries(section).flatMap(([subCategoryKey, items]) =>
      Object.entries(items).map(([itemKey, item]) => ({
        ...item,
        label: itemKey,
        section: sectionKey,
        subCategory: subCategoryKey,
      }))
    )
  );

  const getSiblings = (slug) => {
    for (const section of Object.values(menuData)) {
      for (const items of Object.values(section)) {
        const itemList = Object.values(items);
        const itemIndex = itemList.findIndex((item) => item.slug === slug);

        if (itemIndex !== -1) {
          return itemList.map((item) => (
            <SubNavItem
              key={item.slug}
              item={item}
              label={Object.keys(items).find(
                (key) => items[key].slug === item.slug
              )}
            />
          ));
        }
      }
    }
    return null;
  };

  function handleLogOut() {
    axios.defaults.baseURL = getAxiosBaseUrl();
    axios
      .get("/logout_custom", {
        withCredentials: true, // Ensure cookies are included in the request
      })
      .then((response) => {
        if (isLocalRun) {
          window.location.href = "http://localhost:3002";
        } else {
          window.location.href = "https://acehub.ai";
        }
      })
      .catch((err) => console.log(err));
  }

  if (pathname.startsWith("/auth")) return null;

  return (
    <div className="flex items-center justify-center w-screen fixed top-0 left-0 bg-white bg-opacity-50 backdrop-blur-sm border-b z-40">
      <header
        style={{
          width: "100%", // Set the width
          maxWidth: "1400px", // Set the max width
          boxSizing: "border-box", // Include padding/border in the width
        }}
      >
        <Slide
          duration={location.pathname === "/" ? 500 : 0}
          direction="down"
          className="z-10"
        >
          <nav
            className="relative flex justify-between items-center h-14 px-12  pt-6 overflow-visible"
            style={{ maxWidth: "1400px" }}
          >
            <div className="flex items-center gap-12">
              <div className="flex items-center">
                <a
                  href="/"
                  className="font-bold text-gray-800 text-xl md:text-2xl"
                >
                  <span className="text-color7">
                    Ace<span className="text-color2">Hub</span>.ai
                  </span>
                </a>
              </div>
            </div>

            <div
              className={`xl:px-0 max-w-[65vw] xl:h-12 hidden xl:top-0 xl:py-0 xl:w-fit xl:flex flex-col xl:flex-row gap-4 items-center xl:relative z-20`}
            >
              {Object.keys(menuData).map((key) => {
                const item = menuData[key];

                return (
                  <NavItem key={key} label={key}>
                    <Dropdown setOpen={setMenuOpen} items={item} />
                  </NavItem>
                );
              })}
              {/*<a*/}
              {/*  href="/gallery"*/}
              {/*  className={`font-semibold nav-button lg:w-max w-full text-left px-2 py-1 lg:items-center lg:justify-center flex rounded-md hover:bg-gray-100 text-sm bg-opacity-25`}*/}
              {/*>*/}
              {/*  Community*/}
              {/*</a>*/}
              <a
                href="#testimonials"
                className={`font-semibold nav-button lg:w-max w-full text-left px-2 py-1 lg:items-center lg:justify-center flex rounded-md hover:bg-gray-100 text-sm bg-opacity-25`}
              >
                Testimonials
              </a>
              <a
                href="#pricing"
                className={`font-semibold nav-button lg:w-max w-full text-left px-2 py-1 lg:items-center lg:justify-center flex rounded-md hover:bg-gray-100 text-sm bg-opacity-25`}
              >
                Pricing
              </a>
            </div>

            <div className="flex gap-2 items-center">
              {menuOpen && (
                <div
                  style={{ WebkitBackdropFilter: "blur(10px)" }}
                  className="xl:hidden w-screen h-[96vh] top-14 fixed left-0 bg-black bg-opacity-30 z-10 backdrop-blur-md"
                  onClick={() => setMenuOpen(false)}
                />
              )}

              <div
                className={`${
                  menuOpen
                    ? "transform translate-x-0 !items-start !justify-start"
                    : "transform -translate-x-full"
                } xl:px-0 flex mt-14 h-[95vh] xl:h-auto xl:top-14 top-0 xl:mt-0 left-0 overflow-y-auto absolute bg-white px-4 py-4 w-[85vw] xl:w-fit transition-all duration-100 xl:py-0 xl:hidden flex-col xl:flex-row gap-4 items-center xl:relative z-20 justify-center`}
              >
                {signedin ? (
                  <div className="absolute bottom-12">
                    <div style={{ display: "block", marginLeft: "15px" }}>
                      <p style={{ color: "grey" }}>Account: {verifiedemail}</p>
                    </div>
                    <button
                      className="lg:hidden block font-semibold px-4 py-2 rounded-xl hover:bg-gray-300
                      transition duration-100 cursor-pointer"
                    >
                      Logout
                    </button>
                  </div>
                ) : (
                  <div className="flex gap-2 absolute bottom-12">
                    <button className="lg:hidden block font-semibold px-4 py-2 rounded-xl hover:bg-gray-300 transition duration-100">
                      Login
                    </button>
                    <button className="lg:hidden block font-semibold px-4 py-2 rounded-xl bg-gray-100 hover:bg-gray-300 transition duration-100">
                      Signup
                    </button>
                  </div>
                )}

                {Object.keys(menuData).map((key) => {
                  const item = menuData[key];

                  return (
                    <NavItem key={key} label={key}>
                      <Dropdown setOpen={setMenuOpen} items={item} />
                    </NavItem>
                  );
                })}
              </div>

              {signedin ? (
                <UserMenu
                  verifiedemail={verifiedemail}
                  handleLogOut={handleLogOut}
                />
              ) : (
                <div className="flex">
                  <a
                    href="/signin"
                    className="hidden lg:block font-semibold px-4 py-2 rounded-xl hover:bg-gray-300 transition duration-100"
                  >
                    Login
                  </a>
                  <a
                    href="/register"
                    className="hidden lg:block font-semibold px-4 py-2 rounded-xl bg-gray-100 hover:bg-gray-300 transition duration-100"
                  >
                    Signup
                  </a>
                  <button
                    className="flex justify-center items-center mx-2 px-2 py-2 border bg-[#5865F2] bg-opacity-90 border-gray-400 text-white font-semibold text-sm h-10 rounded-lg border-opacity-10 jiggle-button "
                    onClick={() =>
                      window.open("https://discord.gg/y9pmAxp79v", "_blank")
                    }
                  >
                    <div className="mr-2">Community </div>

                    <div>
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 640 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"></path>
                      </svg>
                    </div>
                  </button>
                  <a
                    href="https://chat.whatsapp.com/CSz2eUZLkaU9CprMU2o9W6"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="w-10 h-10 rounded-lg bg-green-500 p-2 shadow-lg transition-transform transform hover:scale-110 flex items-center justify-center  duration-300"
                  >
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
                      alt="WhatsApp Logo"
                      className="w-full h-full"
                    />
                  </a>
                </div>
              )}
              <div className="xl:hidden">
                <button
                  onClick={() => setMenuOpen(!menuOpen)}
                  className="p-2 focus:outline-none"
                >
                  {menuOpen ? <IoClose /> : <RxHamburgerMenu />}
                </button>
              </div>
            </div>
          </nav>
        </Slide>

        <nav
          id="overflow"
          className="
        md:px-10  flex no-scrollbar justify-between items-start h-8 group"
        >
          {/* <KeyboardDoubleArrowLeftIcon /> */}
          <div
            className={`relative rounded-l-lg rounded-r-lg e overflow-x-auto no-scrollbar lg:px-0 lg:top-0 h-full lg:py-0 w-max lg:flex flex-col lg:flex-row gap-4 items-start  md:group-hover:z-10 md:-z-10 ${
              !menuOpen ? "z-10" : ""
            }`}
          >
            {/* <div className="rounded-l-lg absolute left-0 top-0 h-full w-10 bg-gradient-to-r from-black/10 to-black/0 backdrop-blur-lg z-10 pointer-events-none"></div>

            <div className="rounded-r-lg absolute right-0 top-0 h-full w-10 bg-gradient-to-l from-black/10 to-black/0 backdrop-blur-lg z-10 pointer-events-none" /> */}
            {/* <div className="flex flex-row gap-2 h-full items-start ">
              {location.pathname.startsWith("/article/") && (
                <a href="/">
                  <FaCaretLeft />{" "}
                </a>
              )}

              {location.pathname.startsWith("/article/")
                ? getSiblings(location.pathname.replace("/article/", ""))
                : allItems.map((item) => (
                    <SubNavItem
                      key={item.slug}
                      item={item}
                      label={item.label}
                    />
                  ))}
            </div> */}
          </div>
          {/* <KeyboardDoubleArrowRightIcon /> */}
        </nav>
      </header>
    </div>
  );
};

function SubNavItem({ item, label }) {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <button
      onClick={() => navigate(`/article/${item.slug}`)}
      key={item.slug}
      className={`${
        location.pathname === `/article/${item.slug}` ? "bg-gray-100" : ""
      } py-0.5 capitalize px-2 rounded-md font-semibold text-xs w-full text-nowrap cursor-pointer duration-500 hover:scale-y-[1.3] hover:scale-x-[1.1]`}
    >
      {label}
      {item.premium && <span className="badge-plus ml-2">👑</span>}
    </button>
  );
}
export default NavBar;
