import create from 'zustand';
import axios from 'axios';
import imageCompression from 'browser-image-compression';
import {getAxiosBaseUrl} from "../../config.js";

axios.defaults.baseURL = getAxiosBaseUrl();
axios.defaults.withCredentials = true;

const handleAsync = async (fn) => {
	try {
		return await fn();
	} catch (error) {
		console.error('Error:', error);
		return null;
	}
};

const compressImage = async (file, resolution) => {
	const options = {
		maxWidthOrHeight: resolution,
		useWebWorker: true,
	};
	try {
		const compressedFile = await imageCompression(file, options);
		return compressedFile;
	} catch (error) {
		console.error('Error compressing image:', error);
		throw error;
	}
};

const uploadImage = async (verifiedEmail, file, resolution) => {
	const compressedFile = await compressImage(file, resolution);
	const formData = new FormData();
	formData.append('image', compressedFile);
	formData.append('email', verifiedEmail);
	formData.append('metadataRecordKey', 'profilepic');
	
	return handleAsync(() => axios.post('/uploadImage', formData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	}));
};

const downloadImage = async (verifiedEmail) => {
	return handleAsync(async () => {
		const response = await axios.get(`/downloadImage?email=${verifiedEmail}`, {responseType: 'blob'});
		const blob = response.data;
		const imgUrl = URL.createObjectURL(blob);
		return imgUrl;
	});
};

const fetchUserKeyData = async (verifiedEmail, keyName) => {
  return handleAsync(async () => {
	  console.log('Getting response for ', verifiedEmail);
    const response = await axios.get('/getUserKeyValue', {
      params: {
        email: verifiedEmail,
        keyName: keyName
      }
    });
	  console.log('response', response.data);
    return response.data.keyValue;
  });
};

const fetchGenericAppData = async (verifiedEmail, keyName) => {
	return handleAsync(async () => {
		const response = await axios.get(`/fetchGenericAppData`, {
			params: {
				email: verifiedEmail,
				keyName: keyName
			}
		});
		return response.data;
	});
};


const fetchUserFullName = async (verifiedEmail) => {
	return handleAsync(async () => {
		const response = await axios.get(`/getUserKeyValue?email=${verifiedEmail}&keyName=fullName`);
		return response.data.keyValue;
	});
};

const useMainStore = create((set, get) => ({
	userEmail: '',
	profilePic: null,
	fullName: '', titleTags: '', locationTags: '', sharingCapableEmailIds: [],
	setState: (key, value) => {
		set(state => ({...state, [key]: value}));
	},
	setUserFullName: async (verifiedEmail, fullName) => {
		try {
			await axios.post('/setUserKeyValue', {verifiedEmail, keyName: 'fullName', keyValue: fullName});
			set({fullName});
		} catch (error) {
			console.error('Error setting full name:', error);
		}
	},
	uploadImage: (verifiedEmail, file, resolution) => uploadImage(verifiedEmail, file, resolution),
	downloadImage: async (verifiedEmail) => {
		const imageUrl = await downloadImage(verifiedEmail);
		set({profilePic: imageUrl});
	},
	initializeProfilePic: async (verifiedEmail) => {
		const imageUrl = await get().downloadImage(verifiedEmail);
		set({profilePic: imageUrl});
	},
	initializeUserFullName: async (verifiedEmail) => {
		const fullName = await fetchUserFullName(verifiedEmail);
		set({fullName});
	},
	initializeUserMultipleDataSets: async (verifiedEmail) => {
		const titleTags = await fetchUserKeyData(verifiedEmail, 'titleTags');
		set({titleTags});
		const locationTags = await fetchUserKeyData(verifiedEmail, 'locationTags');
		set({locationTags});
		const sharingCapableEmailIds = await fetchGenericAppData(verifiedEmail, 'sharingCapableEmailIds');
		set({sharingCapableEmailIds})
	},
}));

export default useMainStore;
