import React, { useState, useEffect, useCallback } from 'react';
import ReactPlayer from 'react-player';
import axios from 'axios';
import UploadCard from './UploadCard';

function VideoGallery({ verifiedemail }) {
  const [videos, setVideos] = useState([]);
  const [errorCounts, setErrorCounts] = useState({});
  const [testUrl, setTestUrl] = useState('');
  const [testUrlError, setTestUrlError] = useState(null);

  useEffect(() => {
    fetchVideoLinks();
  }, [verifiedemail]);

  const fetchVideoLinks = async () => {
    try {
      const response = await axios.post('/get-user-videos', { email: verifiedemail });
      setVideos(
        response.data.videos.map((video, index) => ({
          id: index,
          key: video.key,
          url: video.url,
          type: video.type,
          title: `Video ${index + 1}`,
          category: 'Unknown',
          updatedAt: new Date().toISOString(),
          likedBy: [],
          isPublic: video.is_public, // Add a state to track if the video is public
          publicUrl: null,
        }))
      );
    } catch (error) {
      console.error('Error fetching video links:', error);
    }
  };

  const refreshVideoLink = async (videoId, videoKey) => {
    try {
      const response = await axios.post('/refresh-video-link', {
        email: verifiedemail,
        video_key: videoKey,
      });
      setVideos((prevVideos) =>
        prevVideos.map((video) =>
          video.id === videoId ? { ...video, url: response.data.video_url } : video
        )
      );
      setErrorCounts((prev) => ({ ...prev, [videoId]: 0 }));
    } catch (error) {
      console.error('Error refreshing video link:', error);
      alert('Failed to refresh the video link. Please try again later.');
    }
  };

  const handleVideoError = (videoId, videoKey) => {
    setErrorCounts((prev) => {
      const count = prev[videoId] || 0;
      if (count < 3) {
        // Uncomment to enable retrying on error
        // refreshVideoLink(videoId, videoKey);
      } else {
        console.error(`Max retries reached for video ID: ${videoId}`);
      }
      return { ...prev, [videoId]: count + 1 };
    });
  };

  const handleDelete = useCallback(
    async (videoId, videoKey) => {
      const userConfirmed = window.confirm('Are you sure you want to delete this video? This action cannot be undone.');
      if (!userConfirmed) {
        return;
      }
      try {
        await axios.post('/delete-video', {
          email: verifiedemail,
          video_key: videoKey,
        });

        setVideos((prevVideos) => prevVideos.filter((video) => video.id !== videoId));
      } catch (error) {
        console.error('Error deleting video:', error);
        alert('Failed to delete the video. Please try again.');
      }
    },
    [verifiedemail, setVideos]
  );

  // Fetch public URL for the video
  const handleGetPublicUrl = async (videoId, videoKey) => {
    try {
      const response = await axios.post('/get-public-url', {
        email: verifiedemail,
        video_key: videoKey,
      });
      setVideos((prevVideos) =>
        prevVideos.map((video) =>
          video.id === videoId ? { ...video, publicUrl: response.data.public_url, isPublic: true } : video
        )
      );
    } catch (error) {
      console.error('Error fetching public URL:', error);
      alert('Failed to get the public URL. Please try again.');
    }
  };

  // Make the video private
  // const handleMakePrivate = async (videoId, videoKey) => {
  //   try {
  //     await axios.post('/make-video-private', {
  //       email: verifiedemail,
  //       video_key: videoKey,
  //     });
  //     setVideos((prevVideos) =>
  //       prevVideos.map((video) =>
  //         video.id === videoId ? { ...video, publicUrl: null, isPublic: false } : video
  //       )
  //     );
  //   } catch (error) {
  //     console.error('Error making video private:', error);
  //     alert('Failed to make the video private. Please try again.');
  //   }
  // };

  const handleUpload = useCallback(
    (newVideo) => {
      setVideos((prevVideos) => [newVideo, ...prevVideos]);
    },
    [setVideos]
  );

  const handleTestUrlChange = (e) => {
    setTestUrl(e.target.value);
    setTestUrlError(null);
  };

  const handleTestUrlError = () => {
    setTestUrlError('Failed to load video. The URL might not be public or valid.');
  };

  return (
    <div className="flex flex-wrap gap-2 mt-4 w-full">
      <div className="p-4 w-full">
        <UploadCard onUpload={handleUpload} verifiedemail={verifiedemail} />

        <div className="mt-4 p-4 border border-gray-300 rounded-lg bg-white">
          <h3 className="text-sm font-semibold mb-2">Test Public URL</h3>
          <input
            type="text"
            value={testUrl}
            onChange={handleTestUrlChange}
            placeholder="Paste public video URL here..."
            className="w-full p-2 border border-gray-300 rounded mb-2"
          />
          {testUrl && (
            <ReactPlayer
              url={testUrl}
              controls
              width="100%"
              height="220px"
              onError={handleTestUrlError}
            />
          )}
          {testUrlError && (
            <p className="text-xs text-red-600 mt-2">{testUrlError}</p>
          )}
        </div>
      </div>

      <div className="p-4 overflow-y-auto h-full w-full">
        <div className="grid grid-cols-1 gap-4">
          {videos.map((video) => (
              <div
                  key={video.id}
                  className="border border-gray-300 p-2 rounded-lg shadow-sm bg-white"
                  style={{maxWidth: '600px'}}
              >
                <h3 className="text-sm font-semibold mb-1">{video.title}</h3>
                <ReactPlayer
                    url={video.url}
                    controls
                    width="100%"
                    height="220px"
                    className="mb-2"
                    onError={() => handleVideoError(video.id, video.key)}
                    config={{
                      file: {
                        forceHLS: video.type === 'hls',
                        hlsOptions: {
                          debug: true,
                        },
                      },
                    }}
                />
                <p className="text-xs text-gray-600 mb-1">
                  Category: {video.category} Updated: {new Date(video.updatedAt).toLocaleDateString()}{' '}
                  Liked by: {video.likedBy.join(', ')}
                  <button
                      onClick={() => handleDelete(video.id, video.key)}
                      className="text-white bg-red-500 hover:bg-red-600 px-2 py-1 rounded text-xs ml-2"
                  >
                    Delete
                  </button>
                </p>
                
                {/* Display "Get Public URL" or "Make Private" based on the public status of the video */}
                {/*{video.isPublic ? (*/}
                {/*    <button*/}
                {/*        onClick={() => handleMakePrivate(video.id, video.key)}*/}
                {/*        className="text-white bg-yellow-500 hover:bg-yellow-600 px-2 py-1 rounded text-xs ml-2"*/}
                {/*    >*/}
                {/*      Make Private*/}
                {/*    </button>*/}
                {/*) : (*/}
                {/*    <button*/}
                {/*        onClick={() => handleGetPublicUrl(video.id, video.key)}*/}
                {/*        className="text-white bg-blue-500 hover:bg-blue-600 px-2 py-1 rounded text-xs ml-2"*/}
                {/*    >*/}
                {/*      Get Public URL*/}
                {/*    </button>*/}
                {/*)}*/}
                
                <button
                    onClick={() => handleGetPublicUrl(video.id, video.key)}
                    className="text-white bg-blue-500 hover:bg-blue-600 px-2 py-1 rounded text-xs ml-2"
                >
                  Get Public URL
                </button>
                {/* Display the public URL if available */}
                {video.publicUrl && (
                    <p className="text-xs text-blue-600 mt-2 break-words">
                      Public URL: <a href={video.publicUrl} target="_blank" rel="noopener noreferrer">{video.publicUrl}</a>
                    </p>
                )}
              </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default VideoGallery;
