// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BlogComp.module.css */
.YMrj5nVKYAGvIwWBagZV {
  font-size: 14px; /* Adjust the font size as needed */
  height: fit-content;
  padding-bottom: 20px;
  max-width: 100vw;
  overflow-y: hidden;
  background-color: white;
  padding-right: 4px;
  
  img {
    cursor: pointer;
    /*transition: transform 0.3s ease, width 0.3s ease, height 0.3s ease, z-index 0.3s ease;*/
    /*z-index: 1;*/
    /*background-size: cover;*/
    /*background-repeat: no-repeat;*/
    /*background-position: center;*/
  }
  
  /*img:hover {*/
  /*  transform: scaleY(1.5);*/
  /*  transform: scaleX(2);*/
  /*  width: 1200px;*/
  /*  height:700px;*/
  /*  !*border: 2px solid red;*!*/
  /*  filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));*/
  /*}*/
  
  table {
    border-collapse: collapse;
  }

  th,
  td {
    padding: 0px 0px;
    border: 1px solid black;
  }

  p {
    line-height: 1.5;
  }

  code {
  color: black; /* Inherit text color from the parent element */
  background-color: transparent; /* Remove background color */
  font-weight: normal; /* Normal font weight */
  /*font-family: monospace; !* Use a monospace font *!*/
  /*padding: 0.2em 0.4em; !* Optional: Add padding for better readability *!*/
  /*border-radius: 3px; !* Optional: Rounded corners *!*/
}
}
`, "",{"version":3,"sources":["webpack://./src/Components/pages/BlogComp.module.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;EACE,eAAe,EAAE,mCAAmC;EACpD,mBAAmB;EACnB,oBAAoB;EACpB,gBAAgB;EAChB,kBAAkB;EAClB,uBAAuB;EACvB,kBAAkB;;EAElB;IACE,eAAe;IACf,yFAAyF;IACzF,cAAc;IACd,0BAA0B;IAC1B,gCAAgC;IAChC,+BAA+B;EACjC;;EAEA,cAAc;EACd,4BAA4B;EAC5B,0BAA0B;EAC1B,mBAAmB;EACnB,kBAAkB;EAClB,+BAA+B;EAC/B,wDAAwD;EACxD,IAAI;;EAEJ;IACE,yBAAyB;EAC3B;;EAEA;;IAEE,gBAAgB;IAChB,uBAAuB;EACzB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;EACA,YAAY,EAAE,+CAA+C;EAC7D,6BAA6B,EAAE,4BAA4B;EAC3D,mBAAmB,EAAE,uBAAuB;EAC5C,qDAAqD;EACrD,2EAA2E;EAC3E,sDAAsD;AACxD;AACA","sourcesContent":["/* BlogComp.module.css */\n.markdown {\n  font-size: 14px; /* Adjust the font size as needed */\n  height: fit-content;\n  padding-bottom: 20px;\n  max-width: 100vw;\n  overflow-y: hidden;\n  background-color: white;\n  padding-right: 4px;\n  \n  img {\n    cursor: pointer;\n    /*transition: transform 0.3s ease, width 0.3s ease, height 0.3s ease, z-index 0.3s ease;*/\n    /*z-index: 1;*/\n    /*background-size: cover;*/\n    /*background-repeat: no-repeat;*/\n    /*background-position: center;*/\n  }\n  \n  /*img:hover {*/\n  /*  transform: scaleY(1.5);*/\n  /*  transform: scaleX(2);*/\n  /*  width: 1200px;*/\n  /*  height:700px;*/\n  /*  !*border: 2px solid red;*!*/\n  /*  filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));*/\n  /*}*/\n  \n  table {\n    border-collapse: collapse;\n  }\n\n  th,\n  td {\n    padding: 0px 0px;\n    border: 1px solid black;\n  }\n\n  p {\n    line-height: 1.5;\n  }\n\n  code {\n  color: black; /* Inherit text color from the parent element */\n  background-color: transparent; /* Remove background color */\n  font-weight: normal; /* Normal font weight */\n  /*font-family: monospace; !* Use a monospace font *!*/\n  /*padding: 0.2em 0.4em; !* Optional: Add padding for better readability *!*/\n  /*border-radius: 3px; !* Optional: Rounded corners *!*/\n}\n}\n"],"sourceRoot":""}]);
// Exports
export var markdown = `YMrj5nVKYAGvIwWBagZV`;
export default ___CSS_LOADER_EXPORT___;
