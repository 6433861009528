import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { webImages, androidImages } from "./GalleryData";

export function Gallery() {
  const location = useLocation();
  const mergedImages = [...webImages, ...androidImages];
  const showAllImages = location.pathname.endsWith("/gallery");
  const [modalData, setModalData] = useState(null);
  const [selectedNav, setSelectedNav] = useState("discussions"); // Track the selected list item

  const openModal = (imageData) => {
    setModalData(imageData);
  };

  const closeModal = () => {
    setModalData(null);
  };

  const handleNavClick = (item) => {
    setSelectedNav(item); // Set selected list item
  };

  const renderNavContent = () => {
    switch (selectedNav) {
      case "discussions":
        return (
          <div>
            <img
              src="https://del1.vultrobjects.com/blogs-images-and-videos/h1S-6Ftj/h1S-6Ftj_4016437ff6b24bc0a28711cb1cc51d36_WhatsApp_Image_2024-09-28_at_8.50.57_PM.jpeg"
              alt="Discussions"
              className="mb-4 w-full h-[200px] object-cover rounded-lg hover:scale-[1.05]"
            />
            <p className="text-lg">
              Engage in exclusive discussions with experts and peers, exchange
              insights, and network effectively.
            </p>
          </div>
        );
      case "resources":
        return (
          <div>
            <img
              src="https://del1.vultrobjects.com/blogs-images-and-videos/h1S-6Ftj/h1S-6Ftj_acc8367ff70f458a999631cc5a822131_WhatsApp_Image_2024-09-28_at_9.14.35_PM.jpeg"
              alt="Resources"
              className="mb-4 w-full h-[200px] object-cover rounded-lg hover:scale-[1.05]"
            />
            <p className="text-lg">
              Access community-driven learning materials and resources updated
              regularly by industry professionals.
            </p>
          </div>
        );
      case "events":
        return (
          <div>
            <img
              src="https://del1.vultrobjects.com/blogs-images-and-videos/h1S-6Ftj/h1S-6Ftj_4016437ff6b24bc0a28711cb1cc51d36_WhatsApp_Image_2024-09-28_at_8.50.57_PM.jpeg"
              alt="Events"
              className="mb-4 w-full h-[200px] object-cover rounded-lg hover:scale-[1.05]"
            />
            <p className="text-lg">
              Participate in special events and live Q&A sessions with industry
              experts, tailored to enhance your learning.
            </p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="relative flex xl:flex-row flex-col container mx-auto p-4 bg-gray-50  mt-[90px] mb-24">
      <div className="flex flex-col md:px-4 xl:w-4/12 w-full items-center  mb-8 md:mb-0 ">
        <h2 className="text-3xl mt-4 font-bold text-center mb-4">
          Join Our Discord Community
        </h2>
        <div className="text-center mb-6 text-lg">
          <p>Unlock amazing features:</p>
          <ul className="list-none">
            <li
              onClick={() => handleNavClick("discussions")}
              className={`cursor-pointer mb-4 p-2 transition duration-300 rounded-md ${
                selectedNav === "discussions"
                  ? "bg-gray-100 border-r-4 border-blue-500"
                  : "hover:bg-gray-200"
              }`}
            >
              Exclusive discussions and networking opportunities.
            </li>
            <li
              onClick={() => handleNavClick("resources")}
              className={`cursor-pointer mb-4 p-2 transition duration-300 rounded-md ${
                selectedNav === "resources"
                  ? "bg-gray-100 border-r-4 border-blue-500"
                  : "hover:bg-gray-200"
              }`}
            >
              Access to community-driven learning resources.
            </li>
            <li
              onClick={() => handleNavClick("events")}
              className={`cursor-pointer p-2 transition duration-300 rounded-md ${
                selectedNav === "events"
                  ? "bg-gray-100 border-r-4 border-blue-500"
                  : "hover:bg-gray-200"
              }`}
            >
              Special events and live Q&A sessions with experts.
            </li>
          </ul>
        </div>
        <button
          onClick={() => window.open("https://discord.gg/y9pmAxp79v", "_blank")}
          className="z-20 hover:bg-blue-500 xl:absolute xl:mb-0 mb-4 right-4 top-2 mt-4 px-4 py-2 bg-indigo-500 text-white font-semibold rounded-lg opacity-100 group-hover:opacity-100 transition-opacity duration-500"
        >
          Join Us on Discord
        </button>
        <div className="p-4 border rounded-lg bg-white">
          {renderNavContent()}
        </div>
      </div>

      <div className="grid w-full gap-4 px-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 sm:w-full">
        {mergedImages
          .slice(0, showAllImages ? mergedImages.length : 4)
          .map((imageData, index) => (
            <div
              key={index}
              className={`relative group ${
                index % 3 === 1 ? "row-span-2" : ""
              } ${
                index % 3 === 1 ? "h-[400px]" : "h-[220px]"
              } rounded-lg shadow-2xl rounded-[0.5rem]`}
            >
              <img
                src={imageData.src}
                alt={`Image ${index + 1}`}
                className={`w-full h-full rounded-lg object-cover opacity-[0.90] transition-transform duration-700 group-hover:scale-[1.05] group-hover:opacity-100`}
              />
              {/* <div className="absolute inset-0 flex items-center justify-center">
                <button
                  onClick={() => openModal(imageData)}
                  className="px-4 py-2 bg-indigo-500 text-white font-semibold rounded-lg opacity-0 group-hover:opacity-100 hover:bg-blue-400 transition-opacity duration-500"
                >
                  {imageData.buttonText}
                </button>
              </div> */}
            </div>
          ))}
        {!showAllImages && (
          <div className="text-center mt-8">
            <a href="/gallery">
              <button className="px-6 py-3 bg-[#7289da] text-white font-bold rounded-lg hover:bg-blue-500">
                Explore More
              </button>
            </a>
          </div>
        )}
      </div>

      {/* Modal */}
      {modalData && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg p-6 w-3/4 md:w-1/2 lg:w-1/3">
            <h3 className="text-2xl font-bold mb-4">Image Details</h3>
            <img
              src={modalData.src}
              alt="Selected Image"
              className="w-full h-[300px] object-cover mb-4"
            />
            <p className="text-lg">{modalData.description}</p>
            <div className="flex justify-end mt-4">
              <button
                onClick={closeModal}
                className="px-4 py-2 bg-red-500 text-white font-bold rounded-lg"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
