import React, { useState } from "react";
import "../../Projects/AudioGen/MarkdownToSpeech.css";
import ReactPlayer from "react-player";
import { LinkedIn, GitHub, Instagram } from "@mui/icons-material";
import Markdowntoaudio from "../ModelComponent/Markdowntoaudio";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import StarIcon from "@mui/icons-material/Star";
import SchoolIcon from "@mui/icons-material/School";
import BusinessIcon from "@mui/icons-material/Business";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import GradeIcon from "@mui/icons-material/Grade";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import CloudIcon from "@mui/icons-material/Cloud";
import AudioChatbot from "../ModelComponent/AudioChatbot";
import { CheckCircleIcon } from "@heroicons/react/solid";
const contentData = {
  MainHeading:" Audio to Audio Ai Chatbot",
  header: {
    title: "AI-Powered Speech-to-Speech Response Generator",
    description:
      "Master the power of AI with our hands-on voice assistant project! Dive into real-time, audio-to-audio technology, learning to build natural, interactive conversations through advanced speech processing. Ready to learn AI skills with us.",
  },
  features: [
    "Not 14 years old AI, Our projects are state of the art.",
    "Audio to Text Conversion with High Accuracy",
    "Supports multiple languages and dialects.",
    "Text to text answers and responses using LLM of your choice.",
    "Text to Audio response at high speed.",
    "Customizable voice options with adjustable tone, pitch, and emotion.",
  ],
  additionalInfo: [
    { title: "AI-driven conversation flow for natural interactions." },
    { title: "Real-time speech recognition with high accuracy." },
    {
      title: "Integration capabilities with voice assistants and smart devices.",
    },
  ],
  otherDetails: {
    trial: "Trial: Free ",
    duration: "Time : 4 weeks",
    scholarships: "Certification Available",
    career: "Career Track: AI Engineer",
  },
  additionalFeatures: {
    resourceSpent: "Resources: 10,000+ speech interactions processed",
    timeTaken: "Time to Develop: 4 months",
    pricing: "Pricing: Free for members & interns. $250 for one project.",
    rating: "Rating: 4.9/5",
  },
  authorDetails: {
    projectLead: "Krishna Kaushik (AI Researcher)",
    position: "Lead AI Developer",
    modelsUsed: "Google TTS, gTTS, and OpenAI Whisper",
    modelHosted: "Hosted on AWS Cloud Servers",
    accuracy: "85% ",
    image: "profileImageUrl", // Replace with actual image URL
    author: "Dr. Anil Kumar",
    linkedin: "linkedinUrl", // Replace with actual LinkedIn URL
    github: "githubUrl", // Replace with actual GitHub URL
  },
  scale: {
    ActiveLearners: "10,000+ active users",
    Scale: "Global Scale",
  },
};

const ProjectDemo = (modelId) => {
  const [activeTab, setActiveTab] = useState("Feat");
  const [hovered, setHovered] = useState(false);
  const [markdownInput, setMarkdownInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [generatedText, setGeneratedText] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);

  const handleInputChange = (e) => {
    setMarkdownInput(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setGeneratedText(null);
    setAudioUrl(null);

    try {
      const response = await fetch("https://mailchamp.ai:5005/markdown_speak", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ markdown_input: markdownInput }),
      });

      if (!response.ok) {
        throw new Error("Failed to generate speech. Please try again.");
      }

      const data = await response.json();
      setGeneratedText(data.generated_text);
      const audioUrl = `data:audio/wav;base64,${data.audio_base64}`;
      setAudioUrl(audioUrl);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="relative w-[96vw] mt-16 p-4"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className=" mb-12 mx-auto xl:max-w-[78vw] md:w-[90vw] p-4 rounded-2xl bg-gradient-to-r from-gray-700 to-gray-800 shadow-lg ">
        <div className="pulse_running_border">
          <div className="rounded-2xl  text-white p-4 px-4 shadow-2xl flex justify-around lg:flex-row flex-col gap-8 bg-gradient-to-tr from-color3 to-gray-900 ">
            {/* {Left part of card} */}
            <div className="relative lg:pt-0 pt-24 w-full min-h-[70vh] flex flex-col justify-around lg:py-4">
              
              <div className="space-y-2 text-center lg:text-left w-full">
                <h2 className="lg:text-2xl font-bold sm:text-xl">
                  Voice Assistant Project
                </h2>
                {activeTab === "Feat" ? <p className="text-blue-300 text-md">
                  {contentData.header.description}
                </p> : <p></p>}
              </div>

              {/* Tab Buttons */}
              <div className="absolute top-0 right-0 text-sm flex gap-2">
                <button
                  onClick={() => setActiveTab("Feat")}
                  className={`px-4 py-2 ${
                    activeTab === "Feat"
                      ? "bg-gray-800 text-white"
                      : "bg-gray-600 text-gray-300"
                  } rounded-md`}
                >
                  Feature
                </button>
                <button
                  onClick={() => setActiveTab("Author")}
                  className={`px-4 py-2 ${
                    activeTab === "Author"
                      ? "bg-gray-800 text-white"
                      : "bg-gray-600 text-gray-300"
                  } rounded-md`}
                >
                  Author
                </button>
                <button
                  onClick={() => setActiveTab("Other")}
                  className={`px-4 py-2 ${
                    activeTab === "Other"
                      ? "bg-gray-800 text-white"
                      : "bg-gray-600 text-gray-300"
                  } rounded-md`}
                >
                  Other
                </button>
              </div>

              {/* Content Based on Active Tab */}
              <div className="mt-4 space-y-2">
                {activeTab === "Feat" && (
                  // Feat Section
                  <div className="space-y-2">
                    {contentData.features.map((feature, index) => (
                      <div
                        key={index}
                        className="flex items-center gap-2 text-blue-300 border-2 border-transparent hover:border-gray-200 p-2 bg-gray-700 bg-opacity-40 backdrop-blur-lg rounded-lg shadow-sm shadow-gray-500 text-[14px] mb-2"
                      >
                        <span className="text-green-500 bg-zinc-950 p-2 px-[12px] rounded-full">
                          ✔
                        </span>
                        {feature}
                      </div>
                    ))}
                  </div>
                )}

                {activeTab === "Author" && (
                  // Author Section
                  <div
                    className={`transition-all duration-[700ms] ease-in-out z-10 flex flex-col lg:flex-row justify-around  gap-8  `}
                  >
                    <div className="flex flex-col  gap-4 transition-transform duration-[700ms] transform translate-x-hover:translate-x-0 xl:w-[18vw] w-full xl:min-h-[24vh] text-white bg-gray-700 flex flex-col items-start gap-2 bg-gray-100 items-center justify-around p-4 mt-4 rounded-lg p-4 transition-transform transform scale-100 hover:scale-105 ease-in-out">
                      <div className="flex justify-center items-center">
                        <img
                          className="duration-700 select-none group-hover:scale-110 flex-shrink-0 object-cover rounded-full group-hover:rounded-lg w-14 h-14 shadow-2xl mr-2"
                          src={
                            "https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/4/avatar-male-1.png"
                          }
                          alt={contentData.authorDetails.author}
                        />
                        <p className="text-base text-left font-bold text-white cursor-pointer">
                          {contentData.authorDetails.projectLead}
                        </p>
                      </div>

                      <div className="flex-col items-start w-full">
                        <p className="text-lg text-left text-gray-300">
                          Position : {contentData.authorDetails.position}
                        </p>
                      </div>
                      <div
                        className={`flex transition-all duration-200 rounded-b-xl w-full left-0 items-center py-2 justify-around flex-row gap-3 cursor-pointer`}
                      >
                        <a
                          href={contentData.authorDetails.linkedin}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <LinkedIn className="transition-all duration-200 text-white hover:text-gray-400" />
                        </a>
                        <a
                          href={contentData.authorDetails.github}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <GitHub className="transition-all duration-200 text-white hover:text-gray-400" />
                        </a>
                      </div>
                    </div>
                    <div className=" flex flex-col  gap-4 transition-transform duration-[700ms] transform translate-x-hover:translate-x-0 xl:w-[16vw] w-full xl:min-h-[20vh] text-white bg-gray-700 flex flex-col items-start gap-3 bg-gray-100 items-center justify-around p-4 mt-4 rounded-lg p-6 transition-transform transform scale-100 hover:scale-105 ease-in-out ">
                      <div className="flex flex-col items-start w-full justify-around">
                        <div className="flex items-center w-full">
                          <ModelTrainingIcon className="mr-2" />
                          <p className="text-md text-left text-gray-300">
                            Models Used: {contentData.authorDetails.modelsUsed}
                          </p>
                        </div>
                        <div className="flex items-center w-full">
                          <CloudIcon className="mr-2" />
                          <p className="text-md text-left text-gray-300">
                            Model Hosted:{" "}
                            {contentData.authorDetails.modelHosted}
                          </p>
                        </div>
                        <div className="flex items-center w-full">
                          <GradeIcon className="mr-2" />
                          <p className="text-md text-left text-gray-300">
                            Accuracy : {contentData.authorDetails.accuracy}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {activeTab === "Other" && (
                  // Placeholder for Other Tab (if needed in the future)
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-8 text-sm">
                    
                    <div className="bg-gray-700 text-white text-center rounded-lg p-6 transition-transform transform scale-100 hover:scale-105 ease-in-out flex items-center">
                      <AttachMoneyIcon className="mr-2" />
                      {contentData.additionalFeatures.pricing}
                    </div>
                    <div className="bg-gray-700 text-white text-center rounded-lg p-6 transition-transform transform scale-100 hover:scale-105 ease-in-out flex items-center">
                      <StarIcon className="mr-2" />
                      {contentData.additionalFeatures.rating}
                    </div>
                    <div className="bg-gray-700 text-white text-center rounded-lg p-6 transition-transform transform scale-100 hover:scale-105 ease-in-out flex items-center">
                      <CloudIcon className="mr-2" />
                      <p className="text-md text-left text-gray-300">
                        ActiveLearners:{" "}
                        {contentData.scale.ActiveLearners}
                      </p>
                    </div>
                    <div className="bg-gray-700 text-white text-center rounded-lg p-6 transition-transform transform scale-100 hover:scale-105 ease-in-out flex items-center">
                      <GradeIcon className="mr-2" />
                      <p className="text-md text-left text-gray-300">
                        Scale : {contentData.scale.Scale}
                      </p>
                    </div>
                  </div>
                )}
              </div>

              {activeTab === "Feat" ?<>
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-6 mb-2">
                  {contentData.additionalInfo.map((info, index) => (
                    <div
                      key={index}
                      className="p-4 py-2 rounded-lg  border-2 border-gray-600 hover:border-2 hover:border-gray-300 text-center text-white hover:scale-[1.05] duration-500 hover:bg-gray-800 text-md bg-gray-700 bg-opacity-40 backdrop-blur-lg text-[16px]"
                    >
                      <p>{info.title}</p>
                    </div>
                  ))}
                </div>
                <div className="mt-2 flex space-y-2 text-md items-center justify-between px-8 mb-2 w-full">
                  <div>
                    <a href='/Speech_to_speech_Ai_chatbot'>
                    <button  className="mr-4 bg-richYellow p-2  rounded-md">
                      Learn More
                    </button>
                    </a>
                    <a href="/markdowntospeech">
                      <button className="hover:text-richYellow">Explore</button>
                    </a>
                  </div>
                  <div>
                    <button className="mr-4 bg-blue-600 p-2   rounded-md"
                    onClick={() =>
                      window.open("https://discord.gg/y9pmAxp79v", "_blank")
                    }>
                      Join Waitlist
                    </button>
                  </div>
                </div>
              
                <div className="grid grid-cols-2 md:grid-cols-2 gap-4 mt-4 text-sm">
                  <div className="bg-gray-700 text-white text-center rounded-lg p-4 px-4 transition-transform transform scale-100 hover:scale-105 ease-in-out flex items-center">
                    <HourglassEmptyIcon className="mr-2" />
                    {contentData.additionalFeatures.resourceSpent}
                  </div>
                  <div className="bg-gray-700 text-white text-center rounded-lg p-4 px-4 transition-transform transform scale-100 hover:scale-105 ease-in-out flex items-center">
                    <AccessTimeIcon className="mr-2" />
                    {contentData.additionalFeatures.timeTaken}
                  </div>
                  <div className="bg-gray-700 text-white text-center rounded-lg p-4 px-4 transition-transform transform scale-100 hover:scale-105 ease-in-out flex items-center">
                    <AttachMoneyIcon className="mr-2" />
                    {contentData.additionalFeatures.pricing}
                  </div>
                  <div className="bg-gray-700 text-white text-center rounded-lg p-4 px-4 transition-transform transform scale-100 hover:scale-105 ease-in-out flex items-center">
                    <StarIcon className="mr-2" />
                    {contentData.additionalFeatures.rating}
                  </div>
                </div></>
              :<p></p>}
            </div>
          {activeTab === "Feat" ?
            <div className="w-full flex flex-col s lg:space-y-2 justify-around min-h-[68vh] ">
              <div className="flex gap-4">
                <ReactPlayer
                  url="https://blogs-images-and-videos.del1.vultrobjects.com/test1@gmail.com/cbedad86-bfb6-46fa-9440-2dd21a32c7ab/output.m3u8"
                  controls
                  width="80%"
                  height="100%"
                  className=" border-2 border-gray-100  "
                />

                <div className="grid grid-cols-1 md:grid-cols-1 gap-2 mt-[-2px] text-sm">
                  {/*<div className="bg-gray-900 p-1 px-2 flex-col rounded-lg text-white shadow-lg border-2 border-gray-600 hover:border-2 hover:border-gray-300 hover:scale-[1.05] duration-500 flex items-center">*/}
                  {/*  <AccessTimeIcon className="mr-2" />*/}
                  {/*  <p>{contentData.otherDetails.duration}</p>*/}
                  {/*</div>*/}
                  
                  {/*<div className="bg-gray-900 p-1 px-2 flex-col rounded-lg text-white shadow-lg border-2 border-gray-600 hover:border-2 hover:border-gray-300 hover:scale-[1.05] duration-500 flex items-center">*/}
                  {/*  <SchoolIcon className="mr-2" />*/}
                  {/*  <p>{contentData.otherDetails.trial}</p>*/}
                  {/*</div>*/}
                  <div className="bg-gray-900 p-1 flex-col rounded-lg text-white shadow-lg border-2 border-gray-600 hover:border-2 hover:border-gray-300 hover:scale-[1.05] duration-500 flex items-center">
                    <BusinessIcon className="mr-2" />
                    <p>{contentData.otherDetails.career}</p>
                  </div>
                  <div className="bg-gray-900 p-2 px-4 flex-col rounded-lg text-white shadow-lg border-2 border-gray-600 hover:border-2 hover:border-gray-300 hover:scale-[1.05] duration-500 flex items-center">
                    <SchoolIcon className="mr-2" />
                    <p>{contentData.otherDetails.scholarships}</p>
                  </div>
                </div>
              </div>
              <Markdowntoaudio
                handleSubmit={handleSubmit}
                markdownInput={markdownInput}
                handleInputChange={handleInputChange}
                loading={loading}
                generatedText={generatedText}
                audioUrl={audioUrl}
              />
            </div>
              :<p></p>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDemo;
