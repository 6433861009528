import React from "react"
import { Projects } from "../particles/DataLists";
import ProjectCard from "../molecules/ProjectCard";

const ProjectPage = () => {
  return (
    <section className="py-12 bg-gradient-to-b from-gray-50 to-white sm:py-16 lg:py-20 mt-4">
      <div className="px-4 mx-auto my-8 max-w-[90vw] sm:px-6 lg:px-8">
        <h1 className="text-2xl font-semibold mb-2">Projects</h1>
        <div className="flex gap-4 flex-wrap">
          {Projects.map((project, index) => (
            <ProjectCard key={index} course={project} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProjectPage;
