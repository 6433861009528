export const TestimonialTextData = {
    feedBacks: [
      {
        text: "AceHub has been instrumental in upskilling me through hands-on industrial projects and strategic learning approaches. It has deepened my AI concepts by tackling real-world problems, while continuous feedback has guided me on areas to improve. Through this journey, AceHub has not only enhanced my technical skills but also prepared me for placements by strengthening both my technical and soft skills.",
        person: "Krishna Kaushik",
        location: "Chennai, India",
        designation: "AI Engineer",
        imageUrl: "https://i.ibb.co/SfGKgLM/image.png",
        
        github:"https://github.com/krishnakaushik195",
        linkedin:"https://www.linkedin.com/in/krishna-kaushik/",
      },
      {
        text: "AceHub gave me the opportunity to dive deep into AI while also building my full-stack skills. The feedback loop from industry experts helped me fine-tune my approach to projects and accelerated my learning curve.",
        person: "Yash Raj Hans",
        location: "Delhi,India",
        designation: "Full Stack Developer",
        imageUrl: "https://i.ibb.co/r4ZY585/image.png",
        github:"https://github.com/YASH-RAJ-HANS",
        linkedin:"https://www.linkedin.com/in/yash-raj-hans-7827a3224/",
      },
      {
        text: "My experience at AceHub focused on mastering AI tools while understanding deployment in real-world applications. It enhanced my ability to integrate AI into cloud platforms, shaping my career in AI and cloud engineering.",
        person: "Rahul Marban",
        location: "India",
        designation: "AI & Cloud Engineer",
        imageUrl: "https://i.ibb.co/R3PsTmv/image.png",
        linkedin:"https://www.linkedin.com/in/rahul-marban/",
        github:"https://www.linkedin.com/in/rahul-marban/"
      },
      {
        text: "The personalized mentorship I received at AceHub in AI and Data Science was invaluable. It helped me tackle complex data challenges and build AI models that I now use in various business applications.",
        person: "Anushka Gharage",
        location: "Maharashtra , India",
        designation: "Data Scientist",
        imageUrl: "https://i.ibb.co/LpsF4pC/image.png",
    
        linkedin:"https://www.linkedin.com/in/anushka-gharage/",
        github:"https://github.com/AnushkaGharage",
      },
      {
        text: "I’ve had a wonderful experience working as an AI-ML intern at NEUGENCE. The collaborative environment and the guidance have helped me grow both technically and professionally. Working on real-world projects has given me the opportunity to apply my knowledge and learn new skills in machine learning and AI.",
        person: "Putul Singh",
        location: "Himachal Pradesh,India",
        designation: "AI Engineer",
        imageUrl: "https://i.ibb.co/Z2bknXG/image.png",
      },
      {
        text: "AceHub helped me build a solid foundation in AI and machine learning, with a focus on automating business processes. The practical projects and constant feedback made a huge difference in my professional growth.",
        person: "Bhaibhav",
        location: "India",
        designation: "AI Automation Engineer",
        imageUrl: "https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/4/avatar-male-1.png",
      },
    ],
  };
  export const ReviewTextData = {
    firstText: "Our Customers Reviews",
    cards: [
      {
        text: "You did a fabulous job. There were no hitches. Thank you again for all your work planning this trip.",
        person: "Mike taylor",
        rating: "4",
      },
      {
        text: "You did a fabulous job. There were no hitches. Thank you again for all your work planning this trip.",
        person: "Mike taylor",
        rating: "4",
      },
      {
        text: "You did a fabulous job. There were no hitches. Thank you again for all your work planning this trip.",
        person: "Mike taylor",
        rating: "4",
      },
      {
        text: "You did a fabulous job. There were no hitches. Thank you again for all your work planning this trip.",
        person: "Mike taylor",
        rating: "4",
      },
      {
        text: "You did a fabulous job. There were no hitches. Thank you again for all your work planning this trip.",
        person: "Mike taylor",
        rating: "4",
      },
      {
        text: "You did a fabulous job. There were no hitches. Thank you again for all your work planning this trip.",
        person: "Mike taylor",
        rating: "4",
      },
    ],
  };