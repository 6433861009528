var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./Ribbon.css";
import { FaArrowRightLong } from "react-icons/fa6";
export default function ProjectCard(_a) {
    var course = _a.course;
    var id = course.id, image = course.image, stats = course.stats, title = course.title, description = course.description, level = course.level;
    return (_jsxs("div", __assign({ className: "flex transition-all duration-200 my-2 hover:scale-105 flex-col bg-white shadow-[inset_0_-2px_5px_rgba(0,0,0,0.15)] rounded-[24px] max-w-[350px]" }, { children: [_jsx("div", { className: "ribbon select-none cursor-default shadow-md hover:brightness-110 duration-150 hover:shadow-xl hover:bg-[linear-gradient(rgba(255,255,160,0.1),rgba(0,0,150,0.9))] transition-all bg-origin-content rounded-3xl p-0 flex flex-col justify-end min-h-36 max-h-36 min-w-80", style: {
                    backgroundImage: "linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.4)), url(".concat(image, ")"),
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                } }), _jsx("div", __assign({ className: "select-none px-5 py-4 flex flex-row items-center gap-4 justify-between text-black" }, { children: _jsxs("div", __assign({ className: "flex flex-col gap-1 min-w-52" }, { children: [_jsx("h1", __assign({ className: "font-semibold text-2xl pt-0 pl-0", style: { marginTop: "0px", textAlign: "left" } }, { children: title })), _jsx("h3", __assign({ className: "font-semibold text-xs text-gray-600 text-left" }, { children: description }))] })) })), _jsx("div", __assign({ className: "min-h-[60px] items-center justify-between pl-3 pr-2 py-2 flex" }, { children: _jsx("div", __assign({ className: "flex flex-col gap-2 px-1 py-0.5" }, { children: _jsxs("a", __assign({ 
                        // href={`/project?id=${id}`}
                        href: "/Speech_to_speech_Ai_chatbot", className: "select-none text-xs font-bold pl-2 flex gap-2 items-center justify-start" }, { children: ["View ", _jsx(FaArrowRightLong, {})] })) })) }))] })));
}
