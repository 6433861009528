import React, { useState } from "react";
import "./MarkdownToSpeech.css"; // Import the CSS file for styling

const MarkdownToSpeech = () => {
  const [markdownInput, setMarkdownInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [generatedText, setGeneratedText] = useState(null); // State to store generated text
  const [audioUrl, setAudioUrl] = useState(null); // State to store audio URL

  const handleInputChange = (e) => {
    setMarkdownInput(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setGeneratedText(null);
    setAudioUrl(null);

    try {
      const response = await fetch("https://mailchamp.ai:5005/markdown_speak", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ markdown_input: markdownInput }),
      });

      if (!response.ok) {
        throw new Error("Failed to generate speech. Please try again.");
      }

      const data = await response.json();
      setGeneratedText(data.generated_text);

      // Create a URL for the base64 encoded audio
      const audioUrl = `data:audio/wav;base64,${data.audio_base64}`;
      setAudioUrl(audioUrl);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center flex-col justify-center gap-2 max-w-screen">
      <h1 className="text-center font-bold text-gray-500">
        Markdown to Speech Converter
      </h1>
      <form
        onSubmit={handleSubmit}
        className="w-fit max-w-[100vw] flex flex-col items-center justify-center gap-2"
      >
        <textarea
          rows="10"
          placeholder="Click on convert to speech to generate audio of this text. Also you can paste any markdown text here including formulas, bullet points, code pieces and our models will convert it to speakable text."
          value={markdownInput}
          onChange={handleInputChange}
          className="mx-4 lg:w-[650px] md:w-[450px] w-[350px] border border-light-gray p-2 text-gray-500 rounded-lg bg-slate-100 mt-3"
        />
        <button
          type="submit"
          disabled={loading}
          className={`rounded-full p-2 min-h-[40px] min-w-[160px] flex justify-center items-center relative overflow-hidden ${
            loading
              ? "bg-transparent cursor-default"
              : "border border-light-gray bg-white cursor-pointer"
          }`}
        >
          {loading ? (
            <div className="cool-ai-spinner"></div>
          ) : (
            "Convert to Speech"
          )}
        </button>
      </form>
      {error && <p className="text-red-500">{error}</p>}

      <div className="flex flex-col gap-5 items-center justify-center my-4 max-w-screen">
        {generatedText && (
          <div className="flex gap-3 w-full flex-col">
            <h3 className="font-bold">Generated Text:</h3>
            <textarea
              rows="6"
              value={generatedText}
              className="border border-light-gray p-2 lg:w-[650px] md:w-[450px] w-[350px]"
              disabled={true}
            />
          </div>
        )}

        {audioUrl && (
          <div className="flex gap-3 w-full flex-col">
            <h3 className="font-bold">Generated Speech:</h3>
            <audio controls>
              <source src={audioUrl} type="audio/wav" />
              Your browser does not support the audio element.
            </audio>
          </div>
        )}
      </div>

      <div className="flex flex-col lg:w-[60%] px-6 justify-start items-start">
        <p className="mt-1 text-blue-300">
          Project Lead:{" "}
          <span className="font-semibold text-blue-400">
            Baibhav (NSIT Delhi)
          </span>
        </p>
        <p className="mt-1 text-blue-300">
          Models:{" "}
          <span className="font-semibold text-blue-400">
            Google T5 and gTTS library (Hosted On Acehub Servers)
          </span>
        </p>
        <p className="mt-1 text-blue-300">
          Accuracy: <span className="font-semibold text-blue-400">65%</span>
        </p>
        <p className="mt-1 text-blue-300">
          Status: <span className="font-semibold text-blue-400">WIP</span>
        </p>
        <p className="mt-1 text-blue-300">
          Active Learners:{" "}
          <span className="font-semibold text-blue-400">4+</span>
        </p>
      </div>
    </div>
  );
};

export default MarkdownToSpeech;
