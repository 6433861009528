import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import NavBar from "./Components/organs/NavBar";
import BlogComp from "./Components/pages/BlogComp";
import Home from "./Components/pages/Home";
import { ToastContainer } from "react-toastify";
import SlugArticleComp from "./Components/pages/SlugArticleComp";
import ProjectComp from "./Components/pages/ProjectComp";
import Projects from "./Components/pages/Projects";
import ErrorPage from "./Components/pages/Error";
import { SigninForm } from "./Components/molecules/SigninForm";
import { RegisterForm } from "./Components/molecules/RegisterForm";
import TaskRL from "./Components/RL/TaskRL";
import MenuEditor from "./Components/StructureManagement/MenuEditor";
import MarkdownToSpeech from "./Components/Projects/AudioGen/MarkdownToSpeech";
import AuthorProfile from "./Components/pages/AuthorProfile/AuthorProfile";
import Pricing from "./Components/pages/Pricing/Pricing";
import Plan from "./Components/pages/Pricing/Plan";
import Testimonials from "./Components/pages/Reviews/Testimonials";
import axios from "axios";
import { getAxiosBaseUrl } from "./config";
import VideoGallery from "./Components/Media/VideoGallery";
import PrivacyNotice from "./Components/notifications/PrivacyNotice";
import { Gallery } from "./Components/pages/Gallery/Gallery.js";
import MegaNotes from "./Components/Workspace/MegaNotes";
import AudioChatbot from "./Components/pages/ModelComponent/AudioChatbot.js";
import MarkdownToAudioProjectDesc from "./Components/pages/ModelComponent/MarkdownToAudioProjectDesc.js";
import AcehubDetailedFeature from "./Components/pages/AcehubDetailedFeature.js";

function App() {
  const [loading, setLoading] = useState(true);
  const [signedin, setSignedin] = useState(false);
  const [verifiedemail, setVerifiedemail] = useState("");
  const [verificationid, setVerificationid] = useState("");
  const [isLocalRun, setIsLocal] = useState(false);

  function handleClosePrivacyModal() {
    this.setState({ privacyModalOpen: false });
    // localStorage.setItem('privacyAccepted', true);
    // console.log('privacyAccepted', localStorage.getItem('privacyAccepted'))
  }

  // if (localStorage.getItem('privacyAccepted') == 'false') {
  // 	window.location.href = 'https://applyjobs.ai/privacypolicy';
  // }
  // console.log('privacyAccepted', localStorage.getItem('privacyAccepted'));
  // console.log('privacyModalOpen', this.state.privacyModalOpen);

  useEffect(() => {
    const hostname = window.location.hostname;
    if (hostname === "localhost" || hostname === "127.0.0.1") {
      setIsLocal(true);
    }
    getSessionData(); 
  }, []);

  const getSessionData = () => {
    axios.defaults.baseURL = getAxiosBaseUrl();
    axios
      .get("get_session", {
        withCredentials: true,
      })
      .then((response) => {
        console.log("Session data: ", response.data);
        if (response.data.unique_session_id) {
          setSignedin(true);
          setVerifiedemail(response.data.user_email);
          setVerificationid(response.data.firebase_id);
          setLoading(false);
        } else {
          console.log("Unique session ID not found");
        }
      })
      .catch((error) => {
        console.error("Error getting session data:", error);
      });
  };

  return (
    <div className="app-container">
      {/*<Routes>*/}
      {/*	<Route*/}
      {/*		path="/paid-blog"*/}
      {/*		element={<ProtectedRoute element={<PaidBlog/>}/>}*/}
      {/*	/>*/}
      {/*	<Route*/}
      {/*		path="/paid-member-chats"*/}
      {/*		element={<ProtectedRoute element={<PaidMemberChats/>}/>}*/}
      {/*	/>*/}
      {/*</Routes>*/}
      <NavBar
        isLocalRun={isLocalRun}
        signedin={signedin}
        verifiedemail={verifiedemail}
      />
      <Routes>
      <Route path="*" element={<ErrorPage />} />
        <Route path="/" element={<Home />} />
        <Route
          path="/signin"
          element={<SigninForm isLocalRun={isLocalRun} />}
        />
        <Route
          path="/register"
          element={<RegisterForm isLocalRun={isLocalRun} />}
        />
        
        <Route path="/project" element={<ProjectComp />} />
        <Route path="/projects" element={<Projects />} />
        <Route
          path="/course/:courseId"
          element={<BlogComp useremail={"published@neugence.ai"} />}
        />
        <Route path="/article/:slug_id" element={<SlugArticleComp />} />
        <Route path="/tasks_rl" element={<TaskRL />} />
        {/* Wrap MenuEditor with DndProvider */}
        <Route path="/menueditor" element={<MenuEditor />} />
        <Route path="/markdowntospeech" element={<MarkdownToSpeech />} />
        <Route path="/Speech_to_speech_Ai_chatbot" element={<MarkdownToAudioProjectDesc />} />



        <Route path={`/myprofile`} element={<AuthorProfile email={verifiedemail} verifiedemail={verifiedemail}  />} />

        <Route path="/pricing" element={<Pricing />} />
        <Route path="/plan" element={<Plan />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route
          path="/videos"
          element={<VideoGallery verifiedemail={verifiedemail} />}
        />
        <Route path="/privacy-policy" element={<PrivacyNotice />} />
        <Route path="/gallery" element={<Gallery />} />

        <Route
          path="/workspace"
          element={<MegaNotes verifiedemail={verifiedemail} />}
        />
        <Route
          path="/Audio"
          element={<AudioChatbot/>}
        />
        

        <Route path="/acehub-feature/:featureId" element={<AcehubDetailedFeature />} />
      </Routes>
      {/*<NewFooter/>*/}
      
      <ToastContainer />

      {/*DO NO DELETE*/}
      {/*New Responsibilities being transitioned here to this repo*/}
      {/*{this.state.privacyModalOpen && <PrivacyPopup onClose={this.handleClosePrivacyModal}/>}*/}
      {/*// var signedin = sessionStorage.getItem("signedin");*/}
      {/*// const privacyAccepted = localStorage.getItem('privacyAccepted');*/}
      {/*// console.log('privacyAccepted', localStorage.getItem('privacyAccepted'))*/}
      {/*// if (!privacyAccepted) {*/}
      {/*// 	this.setState({privacyModalOpen: false})*/}
      {/*// }*/}
    </div>
  );
}

export default App;
